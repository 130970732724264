<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.booking.header') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.booking.q1')"></div>
    <div v-html="$t('FAQ.booking.a1m1')"></div>
    <br />
    <div v-html="$t('FAQ.booking.a1m2')"></div>
    <br />
    <div v-html="$t('FAQ.booking.a1m3')"></div>
    <hr />
    <div v-html="$t('FAQ.booking.q2')"></div>
    <div v-html="$t('FAQ.booking.a2')"></div>
    <hr />
    <div v-html="$t('FAQ.booking.q3')"></div>
    <div v-html="$t('FAQ.booking.a3')"></div>
    <hr />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
